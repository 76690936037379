* {
  box-sizing: border-box;
  margin: 0;
  font-family: Goldman, -apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;
  color: inherit;
}
html {
  min-width: 100vw;
  min-height: 100vh;
}
body {
  margin: 0;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  color: #ffffff;
}
button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration-line: none;
}
.main-blur-block {
  border: 1px solid #5D92DA;
  border-radius: 28px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(25px);
}

/* 全站多處使用 */
/* because animation need give width，so not write animation name early，use different width animation name, so add name at html */
.animation-default-setting {

  animation-timing-function: linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bg-move-160px {
  0% {
    background-position: 0 0;
  }
  /* 50% {
    background-position: right top;
  } */
  100% {
    background-position: 160px 0;
  }
}
@keyframes bg-move-146px {
  0% {
    background-position: 0 0;
  }
  95% {
    background-position: 146px 0;
  }
  100% {
    background-position: 146px 0;
  }
}
@keyframes bg-move-250px {
  0% {
    background-position: 0 0;
  }
  95% {
    background-position: 250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}
@keyframes bg-move-283px {
  0% {
    background-position: 0 0;
  }
  95% {
    background-position: 283px 0;
  }
  100% {
    background-position: 283px 0;
  }
}

/* linear button make from back and front，back need add padding to make border，border 1px need plus padding: 1px */
.button-linear-back {
  background-image: linear-gradient(91.73deg, #124BB5 8.43%, #AAD9FE 98.17%);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
}
/* linear button make from back and front，back need add padding to make border，border 1px need plus padding: 1px */
.button-linear-front {
  color: transparent;
  background-color: rgba(0, 0, 0);
  border-radius: 8px;
}

.button-linear-text {
  background-image: linear-gradient(91.73deg, #124BB5 8.43%, #AAD9FE 98.17%);
  /* background-size: 200% 200%; */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  border-radius: 8px;
  /* animation-name: bg-move; */
  /* animation-delay: 3s; */
}

.default-blur-block {
  border: 1px solid #5D92DA;
  border-radius: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
  backdrop-filter: blur(25px);
}

/* TODO:未來改用 tailwind */
/* 通用 */
.bg-white-005 {
  background-color: rgba(255, 255, 255, .05);
}
.bg-black {
  background-color: black;
}
.rounded-8px {
  border-radius: 8px;
}
.rounded-16px {
  border-radius: 16px;
}
.rounded-24px {
  border-radius: 24px;
}


.mt-4px {
  margin-top: 4px;
}
.mt-8px {
  margin-top: 8px;
}
.mt-12px {
  margin-top: 12px;
}
.mt-16px {
  margin-top: 16px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-24px {
  margin-top: 24px;
}
.mt-32px {
  margin-top: 32px;
}
.mt-40px {
  margin-top: 40px;
}
.mt-48px {
  margin-top: 48px;
}
.mt-56px {
  margin-top: 56px;
}
.mt-80px {
  margin-top: 80px;
}

.mt-auto {
  margin-top: auto;
}
.ml-auto {
  margin-left: auto;
}

.ml-4px {
  margin-left: 4px;
}
.ml-8px {
  margin-left: 8px;
}
.ml-12px {
  margin-left: 12px;
}
.ml-14px {
  margin-left: 14px;
}
.ml-16px {
  margin-left: 16px;
}
.ml-20px {
  margin-left: 20px;
}
.ml-24px {
  margin-left: 24px;
}
.ml-32px {
  margin-left: 32px;
}
.ml-36px {
  margin-left: 36px;
}
.ml-40px {
  margin-left: 40px;
}
.mr-6px {
  margin-right: 6px;
}
.break-all {
  word-break: break-all;
}
.text-start {
  text-align: start;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.text-12px {
  font-size: 12px;
  line-height: 16px;
}
.text-14px {
  font-size: 14px;
  line-height: 22px;
}
.text-16px {
  font-size: 16px;
  line-height: 24px;
}
.text-18px {
  font-size: 18px;
  line-height: 24px;
}
.text-20px {
  font-size: 20px;
  line-height: 24px;
}
.text-22px {
  font-size: 22px;
  line-height: 24px;
}
.text-24px {
  font-size: 24px;
  line-height: 24px;
}
.text-l-24px {
  font-size: 24px;
  line-height: 48px;
}
.text-28px {
  font-size: 28px;
  line-height: 32px;
}
.text-32px {
  font-size: 32px;
  line-height: 32px;
}
.text-40px {
  font-size: 40px;
  line-height: 48px;
}
.text-start {
  text-align: start;
}
.pre-line {
  white-space: pre-line;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.hidden {
  display: none;
}
.p-24px {
  padding: 24px;
}
.pr-16px {
  padding-right: 16px;
}
.px-8px {
  padding-left: 8px;
  padding-right: 8px;
}
.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}
.px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.px-32px {
  padding-left: 32px;
  padding-right: 32px;
}
.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-24px {
  padding-top: 24px;
  padding-bottom: 24px;
}
.flex-wrap {
  flex-wrap: wrap;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.h-full {
  height: 100%;
}
.w-fit-content {
  width: fit-content;
}
.w-16px {
  width: 16px;
}
.w-24px {
  width: 24px;
}
.h-16px {
  height: 16px;
}
.h-24px {
  height: 24px;
}
.break-word {
  word-break: break-word;
}

.text-white {
  color: #ffffff;
}
.text-black_3 {
  color: rgba(255, 255, 255, 0.30);
}
.text-grey-31 {
  color: #9B9B9B;
}
.text-grey-40 {
  color: #7F7F7F
}
.gap-4px {
  gap: 4px;
}
.gap-8px {
  gap: 8px;
}
.gap-12px {
  gap: 12px;
}
.gap-16px {
  gap: 16px;
}
.gap-20px {
  gap: 20px;
}
.gap-24px {
  gap: 24px;
}

.overflow-hidden {
  overflow: hidden;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


@media screen and (max-width:1024px)   {
  .md-block {
    display: block;
  }
  .md-flex {
    display: flex;
  }
  .md-inline-block {
    display: inline-block;
  }
  .md-justify-center {
    justify-content: center;
  }
  .md-hidden {
    display: none;
  }
  .md-w-full {
    width: 100%;
  }
  .md-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md-w-1\/3 {
    width: 33.3%;
  }
  .md-w-full-important {
    width: 100% !important;
  }
  .md-h-1px-important {
    height: 1px !important;
  }
  .md-h-full-important {
    height: 100% !important;
  }
  .md-h-auto-important {
    height: auto !important;
  }
  .md-text-center {
    text-align: center;
  }
  .md-text-16px {
    font-size: 16px;
  }
  .md-flex-col {
    flex-direction: column;
  }
  .md-flex-row {
    flex-direction: row;
  }
  .md-items-start {
    align-items: flex-start;
  }
  .md-mt-12px {
    margin-top: 12px;
  }
  .md-mt-16px {
    margin-top: 16px;
  }
}