
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: rgba(3, 8, 18, 0.80);
  padding: 16px 40px;
  z-index: 20;
  display: flex;
}
.header-logo {
  width: 196px;
  height: 44px;
}

.header-link {
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.50);
  transition: all 0.3s cubic-bezier(0.15, 0.91, 0.47, 0.96);
}
.header-link:hover {
  color: #ffffff;
}
.header-link.active {
  color: #ffffff;
}
.header-link.disabled {
  color: rgba(255, 255, 255, 0.50) !important;
}
.header-link-block:not(:first-child) {
  margin-left: 20px;
}

.header-button {
  padding: 8px 12px 8px 16px;
}

.header-blue-star-rounded-block {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.custom-border-mui-outline-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  /* border-color: #ffffff !important; */
  border: 1px solid #CCCCCC !important;
}
.custom-border-mui-outline-input.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  /* border-color: #ffffff !important; */
  border: 1px solid #CCCCCC !important;
}
.header-md-menu-block {
  height: calc(100vh - 80px);
  display: none;
  position: absolute;
  /* header bottom */
  top: 80px;
  left: 0;
  padding: 40px 0;
  width: 100vw;
  background: rgba(3, 8, 18, 1);
  flex-direction: column;
  align-items: center;

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: .6s;

}
@media screen and (max-width: 1024px) {
  .header-md-menu-block {
    display: flex;
  }
}

.header-md-menu-item-block:not(:first-child) {
  margin-top: 48px;
}