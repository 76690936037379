
.main-layout {
  background-image: url('../../assets/images/main-background.png');
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
}

.root-container {
  /* white for fixed header height */
  padding-top: 80px;
  padding-bottom: 80px;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: relative;
}
.root-background-fix-image {
  position: fixed;
  /* header height */
  top: 80px;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100%;
  width: 1280px;
  height: 6057px;
  animation-name: fade-img;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  -webkit-transition: all 0.9s ease;
    -moz-transition: all 0.9s ease;
      -o-transition: all 0.9s ease;
          transition: all 0.9s ease;
  animation-direction: alternate;
  
}
.root-content-container {
  position: relative;
  /* need larger than root-background-fix-image */
  z-index: 10;
}

.main-container {
  width: 960px;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .main-container {
    width: 375px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* TODO: here all style, need split to target file  */
    @keyframes fade-img {
        0%{
          opacity: 0;
        }
        100%{
          opacity: 1;
        }
      }
    .root-background-fix-image {
      position: fixed;
      /* header height */
      top: 80px;
      left: 0;
      z-index: 1;
      width: 100vw;
      height: 100%;
      width: 1280px;
      height: 6057px;
      animation-name: fade-img;
      animation-iteration-count: infinite;
      animation-duration: 1s;
      -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
          -o-transition: all 0.9s ease;
              transition: all 0.9s ease;
      animation-direction: alternate;
      
    }
    .titile_coming-soon {
      background: #348FE8;
      padding: 8px 12px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 700;
      line-height: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .banner-block {
      margin-top: 104px;
    }
    .banner-title {
      font-size: 36px;
      line-height: 48px;
      white-space: pre-line;
    }
    .banner-image {
      width: 830px; 
      height: 767px;
    }
    @media screen and (max-width: 1024px) {
      .banner-image {
        width: 368px;
        height: 340px;
      }
    }
    .our-feature_feature-box {
      padding: 24px;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
      backdrop-filter: blur(25px);
      border: 1px solid #5E93DB;
    }
    .our-feature_feature-box:not(:first-child) {
      margin-top: 40px;
    }
    /* linear test */
    .our-feature_feature-box_out-linear {
      /* margin: 10px; */
      /* z-index: 50; */
      border-radius: 24px;
      padding: 1px;
      /* border: 1px solid; */
      /* border-width: 1px; */

      /* border-image-slice: 1;
      border-style: solid; */
      /* border: 1px solid; */
      background: linear-gradient(91.2deg, #5D92DA 4.98%, rgba(93, 146, 218, 0) 112.09%);

      /* border-image-source: linear-gradient(to left,#5D92DA 100%, #5D92DA 0%); */
      /* border-image-repeat: repeat; */
      /* border-image-slice: 100; */
    }
    .our-feature_feature-box_in-linear {
      padding: 24px;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
      backdrop-filter: blur(25px);
    }
    .our-feature_feature-box_title {
      font-size: 22px;
      line-height: 32px;
    }
    .our-feature_feature-box_text {
      font-size: 16px;
      line-height: 24px;
      color: #9B9B9B;
    }
    .roadmap-item-text:not(:first-child) {
      margin-top: 6px;
    }
    .faqs-title {
      font-size: 22px;
      line-height: 32px;
    }
    .faqs-text {
      color: #9B9B9B;
    }
    .go-bot-block {
      border: 1px solid #5D92DA;
      border-radius: 24px;
      padding: 56px 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
      backdrop-filter: blur(25px);
    }
    .footer-icon-link {
      display: block;
    }
    .footer-icon-link:not(:first-child) {
      margin-left: 16px;
    }
    .copy-right {
      color: #7F7F7F;
      font-size: 16px;
      line-height: 32px;
    }
    /* linear button make from back and front，back need add padding to make border，border 1px need plus padding: 1px */
    .button-linear-back {
      background-image: linear-gradient(91.73deg, #124BB5 8.43%, #AAD9FE 98.17%);
      border-radius: 8px;
      width: fit-content;
      height: fit-content;
    }
    /* linear button make from back and front，back need add padding to make border，border 1px need plus padding: 1px */
    .button-linear-front {
      color: transparent;
      background-color: rgba(0, 0, 0);
      border-radius: 8px;
    }
    /* @keyframes bg-move {
      0% {
        background-position: left top;
      }
      50% {
        background-position: right top;
      }
      100% {
        background-position: left top;
      }
    } */

    @keyframes bg-move-160px {
      0% {
        background-position: 0 0;
      }
      /* 50% {
        background-position: right top;
      } */
      100% {
        background-position: 160px 0;
      }
    }
    /* because animation need give width，so not write animation name early，use different width animation name, so add name at html */
    .animation-default-setting {

      animation-timing-function: linear;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
    .tokenomics-box {
      padding: 40px 64px;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
      backdrop-filter: blur(25px);
      border: 1px solid #5E93DB;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 1024px) {
      .tokenomics-box {
        padding: 24px;
      }
    }
    .tokenomics-image {
      margin-left: 36px;
    }
    @media screen and (max-width: 1024px) {
      .tokenomics-image {
        margin-left: 0;
        margin-top: 24px;
      }
    }
    .button-linear-text {
      background-image: linear-gradient(91.73deg, #124BB5 8.43%, #AAD9FE 98.17%);
      /* background-size: 200% 200%; */
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      border-radius: 8px;
      /* animation-name: bg-move; */
      /* animation-delay: 3s; */
    }

    .dot-box {
      padding: 0 4px;
    }

    .tokenomics-distribute {
      margin-left: 36px;
      width: 302px; 
      height: 252px;
    }
    @media screen and (max-width: 1024px) {
      .tokenomics-distribute {
        margin-left: 0;
        margin-top: 32px;
      }
    }

    .frame-a-block {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .frame-a-block.row-reverse {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 1024px) {
      .frame-a-block {
        flex-direction: column;
      }
      .frame-a-block.row-reverse {
        flex-direction: column;
      }
    }
    .frame-a-detail-section {
      max-width: 430px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .frame-a-detail-block {
      display: flex;
    }
    .frame-a-detail-block:not(:first-child) {
      margin-top: 24px;
    }
    .frame-a-image {
      width: 484px;
      height: 293px;
    }
    .frame-a-image.long {
      height: 383px;
    }

    @media screen and (max-width: 1024px) {
      .frame-a-image {
        width: 343px;
        height: 205px;
        margin-top: 24px;
      }
      .frame-a-image.long {
        height: 268px;
      }
    }
    .text-grey {
      color: #9B9B9B;
    }
